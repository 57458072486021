import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../components/HelloWorld.vue'),
    meta: {
      guest: true,
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      guest: true,
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/trains',
    name: 'Trains',
    component: () => import('../components/Trains.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/containers',
    name: 'Containers',
    component: () => import('../components/Containers.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/histocontainer/:container',
    name: 'Containers',
    component: () => import('../components/HistoriqueContainer.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/histocontainer',
    name: 'Containers',
    component: () => import('../components/HistoriqueContainer.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue'),
    meta: {
      guest: true,
    }
  },
  {
    path: '/mentions',
    name: 'Mentions',
    component: () => import('../components/Mentions.vue'),
    meta: {
      guest: true,
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('../components/Support.vue'),
    meta: {
      guest: true,
    }
  },
  {
    path: '/purchaseorder',
    name: 'purchaseorder',
    component: () => import('../components/PurchaseOrderView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/flux',
    name: 'flux',
    component: () => import('../components/FluxView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/utilisateurs',
    name: 'utilisateurs',
    component: () => import('../components/Utilisateurs.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/wagons',
    name: 'wagons',
    component: () => import('@/views/wagons/WagonsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/kpi',
    name: 'kpi',
    component: () => import('@/views/kpi/kpiView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/request-password-reset',
    name: 'motdepasseperdu',
    component: () => import('../components/MotdepassePerdu.vue'),
  },
  {
    path: '/reinit-password',
    name: 'motdepasseperdu',
    component: () => import('../components/ReinitialiserMotdepasse.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Meta Handling
router.beforeEach((to, from, next) => {
  var requireAuth = to.matched.some(record => record.meta.requiresAuth);
  //var requireAdmin = to.matched.some(record => record.meta.requireAdmin);
  if (!requireAuth) {
    // Accès libre
    next();
  }
  else {
    // Accès réservé aux utilisateurs connectés
    let user = JSON.parse(localStorage.getItem('user'))
    if ((user == undefined) || (user == null) || (user.id == undefined) || (user.id == null) || (user.id == "")) {
      // Utilisateur non connecté, redirection vers la page de login
      next({path: '/login' + '?to=' + to.fullPath, params: { nextUrl: to.fullPath }})
    }
    else {
      // Utilisateur connecté
      next();
    }
  }
})

export default router
