<!--
  Composant 'div' en mode flex horizontal
    - Le slot par défaut permet d'insérer horizontalement des éléments
-->
<template>
  <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; align-content: flex-start; flex-wrap: wrap;">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>